'use client';
import React, {useEffect} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import RTL from '@/shared/components/dashboard/layout/shared/customizer/RTL';
import {ThemeSettings} from '@/shared/utils/theme/Theme';
import {useSelector} from 'react-redux';
import {Toaster} from 'react-hot-toast';
import {AppRouterCacheProvider} from '@mui/material-nextjs/v14-appRouter';
import '@/shared/utils/i18n';
import {usePathname, useRouter} from 'next/navigation';
import {getCurrentUser} from '@/shared/redux/slices/users';

const MyApp = ({children}) => {
  const theme = ThemeSettings();
  const pathname = usePathname();
  const router = useRouter();
  const customizer = useSelector((state) => state.customizer);
  const currentUser = useSelector(getCurrentUser);
  const isAuthPage = pathname.includes('auth');

  useEffect(() => {
    if (currentUser?.id && isAuthPage) return router.push('/dashboard');
    if (!currentUser && !isAuthPage) return router.push('/auth/login');
  }, [currentUser]);

  return (
    <>
      <AppRouterCacheProvider options={{enableCssLayer: true}}>
        <ThemeProvider theme={theme}>
          <RTL direction={customizer.activeDir}>
            <CssBaseline />
            {children}
          </RTL>
        </ThemeProvider>
        <Toaster
          position={'top-right'}
          toastOptions={{className: 'react-hot-toast'}}
        />
      </AppRouterCacheProvider>
    </>
  );
};

export default MyApp;
