import {createSlice} from '@reduxjs/toolkit';
import {clearStore} from '@/shared/redux/utils';
import axios from 'axios';
import {showToastUtils} from '@/shared/utils/toast';

// ----------------------------------------------------------------------
const defaultState = {
  isLoading: false,
  error: null,
  currentUser: null,
  notifications: {
    list: [],
  },
  gallery: [],
};

const slice = createSlice({
  name: 'users',
  initialState: defaultState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },

    setNotificationsList(state, action) {
      state.notifications.list = action.payload;
    },

    setGallery(state, action) {
      state.gallery = action.payload;
    },

    addNewNotification(state, action) {
      state.notifications.list.push(action.payload);
    },

    addNewGalleryItem(state, action) {
      state.gallery.push(action.payload);
    },

    updateExistingNotification(state, action) {
      const foundIndex = state.notifications.list.findIndex(
        ({id}) => id == action.payload.id
      );
      state.notifications.list[foundIndex] = {
        ...state.notifications.list[foundIndex],
        ...action.payload,
      };
    },

    deleteNotification(state, action) {
      state.notifications.list = state.notifications.list.filter(
        ({id}) => id !== action.payload
      );
    },

    deleteGalleryItem(state, action) {
      state.gallery = state.gallery.filter(({key}) => key !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

export const actions = slice.actions;

// Selectors

export const getCurrentUser = (state) => state.users.currentUser;

export const getGallery = (state) => state.users.gallery;

export const getNotificationById = (notificationId) => (state) =>
  state.users?.notifications?.list?.find(({id}) => id === notificationId);

export const getNotificationsList = (state) => state.users.notifications.list;

export const loginUser =
  ({email, password}) =>
  async (dispatch) => {
    if (
      email === process.env.NEXT_PUBLIC_ADMIN_EMAIL &&
      password === process.env.NEXT_PUBLIC_ADMIN_PASSWORD
    ) {
      dispatch(actions.setCurrentUser({id: 'ggcwmt-admin', email}));
    } else throw 'Wrong Credentials';
  };

export const fetchNotifications = () => async (dispatch) => {
  try {
    const notificationsList = await axios.get('/api/notifications/get');

    if (notificationsList.data.status === 200) {
      dispatch(
        actions.setNotificationsList(notificationsList.data.notifications)
      );
    } else
      showToastUtils({
        type: 'error',
        message: 'Something went wrong while fetching notifications.',
      });
  } catch (error) {
    showToastUtils({
      type: 'error',
      message: 'Something went wrong while fetching notifications.',
    });
  }
};

export const fetchGallery = () => async (dispatch) => {
  try {
    const gallery = await axios.get('/api/gallery/get');

    if (gallery.data.status === 200) {
      dispatch(actions.setGallery(gallery.data.gallery));
    } else
      showToastUtils({
        type: 'error',
        message: 'Something went wrong while fetching gallery.',
      });
  } catch (error) {
    showToastUtils({
      type: 'error',
      message: 'Something went wrong while fetching gallery.',
    });
  }
};

// Upload File
export const uploadFiles =
  ({formData}) =>
  async (dispatch) => {
    try {
      const gallery = await axios.post('/api/gallery/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (gallery.data.status === 200) {
        await dispatch(fetchGallery());
        showToastUtils({
          type: 'success',
          message: 'Files uploaded successfully.',
        });
      } else
        showToastUtils({
          type: 'error',
          message: 'Something went wrong while fetching gallery.',
        });
    } catch (error) {
      showToastUtils({
        type: 'error',
        message: 'Something went wrong while fetching gallery.',
      });
    }
  };

export const deleteNotification = (id) => async (dispatch) => {
  try {
    const notification = await axios.delete(
      `/api/notifications/delete?id=${id}`
    );
    if (notification.data.status === 200) {
      dispatch(actions.deleteNotification(id));
      showToastUtils({
        type: 'success',
        message: notification.data.message,
      });
    } else
      showToastUtils({
        type: 'error',
        message: 'Something went wrong',
      });
  } catch (error) {
    showToastUtils({
      type: 'error',
      message: 'Something went wrong',
    });
  }
};

export const signOutUser = () => async (dispatch) => {
  dispatch({type: clearStore.type});
};

export const addNewNotification = (notification) => async (dispatch) => {
  dispatch(actions.addNewNotification(notification));
};

export const updateExistingNotification =
  (notification) => async (dispatch) => {
    dispatch(actions.updateExistingNotification(notification));
  };

export const deleteGalleryItem = (key) => async (dispatch) => {
  try {
    const notification = await axios.delete(`/api/gallery/delete?key=${key}`);
    if (notification.data.status === 200) {
      dispatch(actions.deleteGalleryItem(key));
      showToastUtils({
        type: 'success',
        message: notification.data.message,
      });
    } else
      showToastUtils({
        type: 'error',
        message: 'Something went wrong',
      });
  } catch (error) {
    showToastUtils({
      type: 'error',
      message: 'Something went wrong',
    });
  }
};
